import { NgModule } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { EssentIconModule } from '@innogy/icons';
import {
  COMMON_JSS_IMPORTS,
  COMMON_JSS_LAZY_IMPORTS,
  CoreModulesCommonClientModule,
  CoreModulesCommonModule,
} from '@innogy/core/modules/common';
import {
  CoreModulesOpenModule,
  OPEN_JSS_IMPORTS,
  OPEN_JSS_LAZY_IMPORTS,
} from '@innogy/core/modules/open';
import { NavigationEssentOpenModule } from '@innogy/navigation/essent-open';
import { UtilsModule } from '@innogy/utils-deprecated';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { VERTICAL_SCROLL_OFFSET } from '@core/config-angular';

import { EssentZakelijkAppComponent } from './app.component';

export const DEPLOY_PATH_VALUE = 'dist/essent-zakelijk/browser';
export const DEPLOY_PATH_PROVIDER = {
  provide: 'DEPLOY_PATH',
  useValue: DEPLOY_PATH_VALUE,
};

export const VERTICAL_SCROLL_OFFSET_PROVIDER = {
  provide: VERTICAL_SCROLL_OFFSET,
  useValue: 133,
};

export const sharedImports = [
  CoreModulesCommonModule.forRoot({
    appName: 'essent-zakelijk',
    basePath: '/zakelijk',
  }),
  CoreModulesOpenModule,
  EssentIconModule,
  NavigationEssentOpenModule,
  JssModule.withComponents(
    [...COMMON_JSS_IMPORTS, ...OPEN_JSS_IMPORTS],
    [...COMMON_JSS_LAZY_IMPORTS, ...OPEN_JSS_LAZY_IMPORTS]
  ),
];

@NgModule({
  imports: [
    CoreModulesCommonClientModule.forRoot(),
    UtilsModule.forRoot({
      brandColor: '#e60167',
      brandColorDark: '#aa014c',
    }),
    ...sharedImports,
  ],
  declarations: [EssentZakelijkAppComponent],
  providers: [
    DEPLOY_PATH_PROVIDER,
    VERTICAL_SCROLL_OFFSET_PROVIDER,
    provideClientHydration(),
  ],
  bootstrap: [EssentZakelijkAppComponent],
})
export class EssentZakelijkAppModule {}
